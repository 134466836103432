<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.genres')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <tr>
              <th scope="col">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('sideBar.genres') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="genresStatus !== 'success'">
              <td><SpinnerLoader :loading="genresStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <button v-if="canEdit" class="btn-icon" @click="showModalGenres(row.id)">
                      <b-icon icon="pencil"></b-icon>
                      {{ $t('table.edit') }}
                    </button>
                    <button v-if="canDelete" class="btn-icon mr-4" @click="showModalDeleteGenres(row)">
                      <b-icon icon="trash"></b-icon>
                      {{ $t('table.delete') }}
                    </button>
                    {{ row.name }}
                  </div>
                </td>
              </tr>
              <tr v-if="genresStatus === 'success' && !tableData.length">
                <td>
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('genre.create')" variant="primary" @click="showModalGenres()">
          {{ $t('table.addNewGenres') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD/EDIT GENRE-->
      <b-modal
        id="genres-modal"
        ref="genres-modal"
        :title="modalType === 'add' ? $t('table.addNewGenres') : $t('table.editGenres')"
        :ok-disabled="!$v.writeTitle.required"
        :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :busy="isModalBusy"
        @ok.prevent="checkIfValidThenEnter"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-genre-name">
            <b-form-input id="input-genre-name" v-model.trim="$v.writeTitle.$model" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SortArrows from '../components/SortArrows';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';

export default {
  name: 'Genres',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },

  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      writeTitle: '',
      filterPage: 1,
      selectedPerPage: '100',
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      genres: 'getGenres',
      genresStatus: 'getGenresStatus',
      modalEditGenres: 'getModalEditGenres',
    }),
    canEdit: function () {
      return this.$checkPermissions('genre.update');
    },
    canDelete: function () {
      return this.$checkPermissions('genre.delete');
    },
  },
  validations: {
    writeTitle: { required },
  },
  watch: {
    genres() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.genres') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getGenresInfo();
  },
  destroyed() {
    this.$store.commit('clearGenresList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_GENRES', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // per page
    perPage() {
      this.filterPage = 1;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.genres) {
        this.tableData = this.genres.data;
        this.paginationData = this.genres.pagination;
        if (this.filterPage > this.genres.pagination.last_page) {
          this.filterPage = this.genres.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getGenresInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    async showModalGenres(data) {
      this.isModalLoading = true;
      data ? (this.modalType = 'edit') : (this.modalType = 'add');
      this.$refs['genres-modal'].show();
      if (data) {
        await this.$store.dispatch('GET_GENRES_ID', data);
        this.writeTitle = this.modalEditGenres.name;
      }
      this.isModalLoading = false;
    },

    async addEditGenres() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditGenres.id,
        name: this.writeTitle,
      };
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_GENRES' : 'PUT_GENRES', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('alert.addGenres') : this.$i18n.t('alert.editGenres'),
            text: this.writeTitle,
          });
          this.$refs['genres-modal'].hide();
          this.paramsData();
          this.writeTitle = '';
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteGenres(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteGenres')} "${data.name}"?`, {
          title: this.$i18n.t('table.deleteGenres'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_GENRES_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteGenres'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidThenEnter() {
      if (this.$v.writeTitle.required && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('table.confirmAddGenres')} "${this.writeTitle}"?`
              : `${this.$i18n.t('table.confirmEditGenres')} "${this.writeTitle}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('table.addGenres') : this.$i18n.t('table.editGenres'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-genre-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditGenres();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="sass"></style>
